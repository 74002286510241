var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex box-border bg-white min-w-[546px] min-h-[364px] rounded-xl overflow-hidden"},[_c('div',{staticClass:"flex flex-col py-6",class:("" + (_vm.showList ? 'w-2/3 border-r ' : 'w-full'))},_vm._l((_vm.sections),function(item,idx){return _c('div',{key:idx,staticClass:"group relative flex space-x-5 py-3 px-8 border-l-4 border-l-transparent hover:cursor-pointer hover:bg-neutral-50 border-y border-y-transparent hover:border-y-neutral-200",class:{
                'hover:border-l-[#E37712] hover:text-[#E37712]':
                    item.id === _vm.RESOURCE_TYPE.FREEBIES,
                'hover:border-l-[#6D28D9] hover:text-theme-primary':
                    item.id === _vm.RESOURCE_TYPE.GUIDE,
                'hover:border-l-[#00838A] hover:text-[#00838A]': item.id === _vm.RESOURCE_TYPE.BLOG,
                'hover:border-l-[#A16207] hover:text-[#A16207]':
                    item.id === _vm.RESOURCE_TYPE.CONTACT
            },on:{"click":function($event){return _vm.redirect(item)},"mouseover":function($event){return _vm.toggleSubList(item.id)},"mouseleave":function($event){return _vm.toggleSubList(_vm.RESOURCE_TYPE.GUIDE)}}},[_c('img',{staticClass:"h-10 w-10",attrs:{"src":item.icon,"height":"40px","width":"40px"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col box-border w-full text-sm"},[_c('p',{staticClass:"font-bold"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('p',{staticClass:"text-theme-gray-dark"},[_vm._v(_vm._s(item.subtitle))])]),_vm._v(" "),(_vm.showList)?_c('div',{staticClass:"absolute -right-[1px] w-0.5 top-0 h-full bg-neutral-50 hidden group-hover:block"}):_vm._e()])}),0),_vm._v(" "),(_vm.showList)?_c('div',{staticClass:"py-5 px-4 rounded-tr-xl",class:("" + (_vm.showList ? 'w-1/3 bg-neutral-50' : ''))},[_c('ul',{staticClass:"rounded-lg"},_vm._l((_vm.GRE_TOPICS),function(item,index){return _c('li',{key:index,staticClass:"flex text-sm font-medium whitespace-no-wrap min-w-max hover:font-bold",on:{"click":function($event){_vm.showDropDown = false}}},[_c('NuxtLink',{staticClass:"py-2 px-3",attrs:{"to":("" + (item.redirection))},nativeOn:{"click":function($event){return _vm.emitEvent('guide', item.topic)}}},[_vm._v("\n                    "+_vm._s(item.topic)+"\n                ")])],1)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }