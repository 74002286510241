//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Dropdown',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        showDividers: {
            type: Boolean,
            default: false
        },
        showIcons: {
            type: Boolean,
            default: false
        },
        showSelection: {
            type: Boolean,
            default: false
        },
        showHover: {
            type: Boolean,
            default: true
        },
        selectedOption: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        formattedOptions() {
            return this.options.map((item) => {
                return Array.isArray(item) ? item : [item];
            });
        }
    },
    methods: {
        selectItem(option) {
            this.$emit('selectOption', option);
        }
    }
};
