var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full relative z-50"},[(!_vm.isDropdown)?_c('div',{staticClass:"h-full"},[(!_vm.externalLink)?_c('NuxtLink',{staticClass:"text-sm h-full flex items-center",class:{
                'font-bold bg-gradient-to-r from-[#6D28D9] to-[#0275D8] bg-clip-text text-transparent':
                    _vm.isGradientText,
                'nav-links text-theme-gray-dark': !_vm.isGradientText,
                '!mr-6': _vm.showTag
            },attrs:{"to":_vm.redirectTo,"prefetch":true},nativeOn:{"click":function($event){return _vm.$emit('select')}}},[(_vm.icon)?_c('img',{staticClass:"mr-1",attrs:{"src":_vm.icon,"alt":_vm.label,"height":"20","width":"20"}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),(_vm.showTag)?_c('span',{staticClass:"font-bold text-[10px] leading-[10px] px-1.5 py-0.5 rounded-sm absolute top-1.5 -right-2.5",class:_vm.tagColor === 'yellow'
                        ? 'yellow-tag-gradient text-[#A16207]'
                        : 'purple-tag-gradient text-[#6002D8]'},[_vm._v("\n                "+_vm._s(_vm.tagLabel)+"\n            ")]):_vm._e()]):_c('a',{staticClass:"text-sm text-theme-gray-dark h-full inline-flex items-center relative",class:{
                'font-bold bg-gradient-to-r from-[#6D28D9] to-[#0275D8] bg-clip-text text-transparent':
                    _vm.isGradientText,
                'nav-links text-theme-gray-dark': !_vm.isGradientText,
                '!mr-6': _vm.showTag
            },attrs:{"href":_vm.externalLink,"target":"_blank","data-prefetch":true},on:{"click":function($event){return _vm.$emit('select')}}},[(_vm.icon)?_c('img',{staticClass:"mr-1",attrs:{"src":_vm.icon,"alt":_vm.label,"height":"20","width":"20"}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),(_vm.showTag)?_c('span',{staticClass:"text-[#6002D8] font-bold text-[10px] leading-[10px] px-1.5 py-0.5 rounded-sm purple-tag-gradient absolute top-1.5 -right-0",class:_vm.tagColor === 'yellow'
                        ? 'yellow-tag-gradient text-[#A16207]'
                        : 'purple-tag-gradient text-[#6002D8]'},[_vm._v("\n                "+_vm._s(_vm.tagLabel)+"\n            ")]):_vm._e()])],1):_c('div',{staticClass:"relative flex z-50 h-full items-center",class:{ 'border-b-2 border-theme-primary': _vm.isActive },on:{"mouseover":function($event){_vm.showDropDown = true},"mouseleave":function($event){_vm.showDropDown = false}}},[_c('div',{staticClass:"flex space-x-2 text-sm font-medium text-theme-gray-dark cursor-pointer"},[_c('p',{staticClass:"hover:text-theme-black-primary",class:{ 'text-theme-primary': _vm.isActive }},[_vm._v("\n                "+_vm._s(_vm.label)+"\n            ")]),_vm._v(" "),(_vm.isDropdown)?_c('img',{staticClass:"transition-all duration-150",class:("" + (_vm.showDropDown ? 'rotate-180' : '')),attrs:{"src":require("static/icons/chevronGrayBold.svg"),"alt":"chevron"}}):_vm._e()]),_vm._v(" "),(_vm.isDropdown && _vm.showDropDown)?_c('div',{staticClass:"absolute shadow-md rounded-lg top-14",class:[_vm.reposition],on:{"mouseover":function($event){_vm.showDropDown = true},"mouseleave":function($event){_vm.showDropDown = false}}},[(!_vm.isListDropdown)?_c('div',{staticClass:"pt-3"},[_vm._t("default")],2):_c('ul',{staticClass:"bg-white rounded-lg"},_vm._l((_vm.listItems),function(item,index){return _c('li',{key:index,staticClass:"flex text-sm font-medium whitespace-no-wrap min-w-max hover:bg-[#F2F4F7]",on:{"click":function($event){_vm.showDropDown = false}}},[_c('NuxtLink',{staticClass:"py-2 px-3",attrs:{"to":("" + (item.redirection))}},[_vm._v("\n                        "+_vm._s(item.topic)+"\n                    ")])],1)}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }