//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'RedirectionCard',
    props: {
        cardDetails: {
            type: Object,
            default: () => {
                return {
                    title: 'Explore',
                    subtitle: 'Countries Colleges & Courses',
                    bgConfig:
                        'bg-cover bg-[url(https://s3.ap-southeast-1.amazonaws.com/static.yocket.in/testprep/static/cf_prep_nav.svg)]',
                    redirection: 'https://yocket.com/universities?source=prep_nav'
                };
            }
        },
        externalRedirection: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mouseover: false
        };
    },
    methods: {
        redirect(value) {
            if (this.externalRedirection) {
                window.open(value, '_blank');
            } else {
                this.$router.push(value);
            }
        }
    }
};
