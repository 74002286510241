var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-5 py-6 min-w-[245px] bg-white items-center rounded-xl overflow-hidden"},_vm._l((_vm.DASHBOARD_REDIRECTION),function(item,idx){return _c('div',{key:idx,staticClass:"flex space-x-5 items-center hover:cursor-pointer px-7",class:("" + (item.id === _vm.DASHBOARD_MODES.PRACTICE
                ? 'hover:text-[#6D28D9]'
                : 'hover:text-[#00838A]')),on:{"click":function($event){return _vm.redirectToDashboard(item.id)}}},[_c('img',{attrs:{"src":item.icon}}),_vm._v(" "),_c('p',{staticClass:"text-sm font-bold",class:{
                'text-[#6D28D9]':
                    _vm.isDashboardPage &&
                    _vm.dashboardMode === _vm.DASHBOARD_MODES.PRACTICE &&
                    item.id === _vm.DASHBOARD_MODES.PRACTICE,
                'text-[#00838A]':
                    _vm.isDashboardPage &&
                    _vm.dashboardMode === _vm.DASHBOARD_MODES.LEARNING &&
                    item.id === _vm.DASHBOARD_MODES.LEARNING
            }},[_vm._v("\n            "+_vm._s(item.title)+"\n        ")])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }