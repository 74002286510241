//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ROUTES } from '~/constants/pageRoutes';

export default {
    name: 'NavItemWeb',
    props: {
        label: {
            type: String,
            default: '[LABEL]',
            required: true
        },
        icon: {
            type: String,
            default: ''
        },
        redirectTo: {
            type: String,
            default: ROUTES.HOME
        },
        isDropdown: {
            type: Boolean,
            default: false
        },
        // used for tabs that contain a dropdown menu
        isActive: {
            type: Boolean,
            default: false
        },
        showTag: {
            type: Boolean,
            default: false
        },
        tagLabel: {
            type: String,
            default: '[TAG LABEL]'
        },
        tagColor: {
            type: String,
            default: 'purple' // purple/yellow
        },
        externalLink: {
            type: String,
            default: ''
        },
        isGradientText: {
            type: Boolean,
            default: false
        },
        isListDropdown: {
            type: Boolean,
            default: false
        },
        //  Pass this list if just a list is to be rendered
        listItems: {
            type: Array,
            default: () => []
        },
        // Reposition Navbar dropdown as per absolute position.
        reposition: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showDropDown: false
        };
    }
};
