//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { DASHBOARD_REDIRECTION, DASHBOARD_MODES } from '~/static/constants.js';
import { ROUTES } from '~/constants/pageRoutes';

export default {
    name: 'DashboardSelector',
    data() {
        return {
            DASHBOARD_REDIRECTION,
            DASHBOARD_MODES
        };
    },
    computed: {
        ...mapGetters({
            dashboardMode: 'dashboard/dashboardMode'
        }),
        isDashboardPage() {
            return this.$route.path.includes(ROUTES.DASHBOARD);
        }
    },
    methods: {
        redirectToDashboard(value) {
            if (value === DASHBOARD_MODES.LEARNING) {
                this.$store.commit('dashboard/setDashboardMode', DASHBOARD_MODES.LEARNING);
                this.$emit('fireEvent', 'learning_dashboard');
            } else {
                this.$store.commit('dashboard/setDashboardMode', DASHBOARD_MODES.PRACTICE);
                this.$emit('fireEvent', 'practice_dashboard');
            }

            this.$router.push(ROUTES.DASHBOARD);
        }
    }
};
