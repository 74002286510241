//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { premiumIcon, avatar } from '~/static/icons/index';

const DEFAULT_DIMENSIONS = {
    avatar: {
        height: 76,
        width: 76
    },
    premiumIcon: {
        height: 22,
        width: 31,
        bottomShift: -8
    },
    padding: 4
};

export default {
    name: 'ProfilePic',
    props: {
        isPremiumUser: {
            type: Boolean,
            default: false
        },
        widthInPixels: {
            type: Number,
            default: 76
        }
    },

    data() {
        return {
            avatar,
            premiumIcon
        };
    },

    computed: {
        dimensionsMultiplier() {
            return this.widthInPixels / DEFAULT_DIMENSIONS.avatar.height;
        },
        outerRingStyle() {
            return {
                height:
                    Math.floor(DEFAULT_DIMENSIONS.avatar.height * this.dimensionsMultiplier) + 'px',
                width:
                    Math.floor(DEFAULT_DIMENSIONS.avatar.width * this.dimensionsMultiplier) + 'px'
            };
        },
        dynamicPadding() {
            return {
                padding: DEFAULT_DIMENSIONS.padding * this.dimensionsMultiplier + 'px'
            };
        },
        premiumIconStyle() {
            return {
                height:
                    Math.floor(DEFAULT_DIMENSIONS.premiumIcon.height * this.dimensionsMultiplier) +
                    'px',
                width:
                    Math.floor(DEFAULT_DIMENSIONS.premiumIcon.width * this.dimensionsMultiplier) +
                    'px',
                bottom:
                    Math.floor(
                        DEFAULT_DIMENSIONS.premiumIcon.bottomShift * this.dimensionsMultiplier
                    ) + 'px'
            };
        }
    }
};
