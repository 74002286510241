//
//
//
//
//
//
//
//
//
//
//

import RedirectionCard from '~/components/Navbar/Web/RedirectionCard.vue';
import { ROUTES } from '~/constants/pageRoutes';

export default {
    name: 'PrepToolsSelector',
    components: {
        RedirectionCard
    },
    data() {
        return {
            prepTools: [
                {
                    title: 'Study Planner',
                    subtitle: 'Create an organised study plan',
                    bgConfig: 'bg-cover bg-[url(~/static/images/plannerBg.png)]',
                    redirection: ROUTES.PLANNER_LP, // Navguard will automatically reroute it to plan page if it is set
                    eventFor: 'planner'
                },
                {
                    title: 'Vocab Builder',
                    subtitle: 'Learn 20 new words everyday',
                    bgConfig: 'bg-cover bg-[url(~/static/images/vocabBg.png)]',
                    redirection: ROUTES.VOCAB,
                    eventFor: 'vocabConnect'
                }
            ]
        };
    }
};
