//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { GRE_TOPICS } from '~/static/constants.js';
import { guideIcon, blogIcon, freebiesIcon, contactOutlineOrange } from '~/static/icons/index';
import { ROUTES } from '~/constants/pageRoutes';

const RESOURCE_TYPE = {
    FREEBIES: 1,
    GUIDE: 2,
    BLOG: 3,
    CONTACT: 4
};

export default {
    name: 'ResourceSelector',
    props: {
        windowWidth: { type: Number, default: 0 }
    },
    data() {
        return {
            // Data
            GRE_TOPICS,
            RESOURCE_TYPE,
            sections: [
                {
                    id: RESOURCE_TYPE.GUIDE,
                    title: 'GRE Guide',
                    subtitle: 'A GRE-Specific library of information',
                    icon: guideIcon,
                    redirection: ROUTES.BLOGS_AND_GUIDES,
                    eventFor: 'guide'
                },
                {
                    id: RESOURCE_TYPE.BLOG,
                    title: 'GRE Blogs',
                    subtitle: 'Expert-curated GRE Tips and Tricks',
                    icon: blogIcon,
                    redirection: ROUTES.BLOG_LISTING,
                    eventFor: 'blogs'
                }
            ],
            activeSection: {},
            showList: true,
            showContactUsTab: false
        };
    },
    computed: {
        ...mapGetters({
            hasPrepFreebies: 'auth/hasPrepFreebies',
            isFreeTrialExpired: 'auth/isFreeTrialExpired',
            isPremiumUser: 'auth/isPremiumUser'
        })
    },
    watch: {
        showContactUsTab(newVal) {
            if (newVal) {
                // this.sections.push({
                //     id: RESOURCE_TYPE.CONTACT,
                //     title: 'Contact us',
                //     subtitle: 'Talk to our experts',
                //     icon: contactOutlineOrange,
                //     redirection: 'https://tally.so/r/3E5eVq',
                //     external: true,
                //     eventFor: 'greConsultation'
                // });

                this.sections.push({
                    id: RESOURCE_TYPE.CONTACT,
                    title: 'Book a Demo',
                    subtitle:
                        'Talk to our experts and understand if the GRE classes are a fit for you',
                    icon: contactOutlineOrange,
                    redirection: ROUTES.BOOK_DEMO,
                    external: true,
                    eventFor: 'bookDemo'
                });
            } else {
                this.sections = this.sections.filter((item) => item.id !== RESOURCE_TYPE.CONTACT);
            }
        }
    },
    mounted() {
        // Condition to show freebies tab
        if (!this.isPremiumUser && this.hasPrepFreebies && !this.isFreeTrialExpired) {
            this.sections.unshift({
                id: RESOURCE_TYPE.FREEBIES,
                title: 'Your Freebies',
                subtitle: 'Cheatsheet formula and Videos',
                icon: freebiesIcon,
                redirection: ROUTES.FREEBIES,
                eventFor: null
            });
        }

        // Condition to show contact us tab
        this.showContactUsTab = this.windowWidth < 1280 && !this.isPremiumUser;
    },

    methods: {
        redirect(value) {
            if (value.external) {
                window.open(value.redirection, '_blank');
            } else {
                this.$router.push(`${value.redirection}`);
            }

            if (value.eventFor) {
                this.emitEvent(value.eventFor);
            }
        },
        emitEvent(event, payload = null) {
            const payloadObjToEmit = {
                eventFor: event
            };

            if (payload) {
                payloadObjToEmit.resource = payload;
            }

            this.$emit('fireEvent', payloadObjToEmit);
        },
        toggleSubList(value) {
            if (value === RESOURCE_TYPE.GUIDE) {
                this.showList = true;
            } else {
                this.showList = false;
            }
        }
    }
};
