//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { liveClassNavIcon, onDemandNavIcon } from '~/static/icons/index';
import analyticEvents from '~/services/analyticEvents';
import { findValidPermission } from '~/services/utilityFunctions';
import { PERMISSIONS } from '~/static/constants.js';
import { ROUTES } from '~/constants/pageRoutes';

const COURSES = {
    LIVE_CLASS: 1,
    ON_DEMAND: 2
};

export default {
    name: 'PrepCourseSelector',
    data() {
        return {
            // Images
            liveClassNavIcon,
            onDemandNavIcon,

            // Constants
            PERMISSIONS,
            analyticEvents,

            // Data
            COURSES,
            NAV_ITEMS: []
        };
    },
    computed: {
        ...mapGetters({
            isLiveClassUpgradeAvailable: 'auth/isLiveClassUpgradeAvailable',
            isClassesAccessible: 'auth/isClassesAccessible',
            isLearningAidAccessible: 'auth/isLearningAidAccessible'
        }),
        liveClassPermission() {
            return findValidPermission(
                PERMISSIONS.GRE_LIVE_CLASS_ACCESS,
                this.getUserPremiumFeatures
            );
        },
        recordedClassPermission() {
            return findValidPermission(
                PERMISSIONS.GRE_RECORDED_CLASS_ACCESS,
                this.getUserPremiumFeatures
            );
        }
    },
    mounted() {
        const liveClassTab = {
            id: COURSES.LIVE_CLASS,
            title: 'Live Online GRE Course',
            subtitle: 'Live GRE Classes and ace the exam',
            icon: liveClassNavIcon,
            redirection: ROUTES.CLASSES_LP,
            eventFor: 'liveClass'
        };

        const onDemandTab = {
            id: COURSES.ON_DEMAND,
            title: 'On-Demand GRE Course',
            subtitle: 'GRE Course Videos with Practice & Live Doubt Sessions',
            icon: onDemandNavIcon,
            redirection: ROUTES.ON_DEMAND,
            eventFor: 'onDemand'
        };

        this.NAV_ITEMS = [liveClassTab, onDemandTab];
    },
    methods: {
        findValidPermission,
        redirect(item) {
            this.$router.push(item.redirection);

            if (item.eventFor) {
                this.$emit('fireEvent', item.eventFor);
            }
        }
    }
};
